import validator from 'validator';
import { z } from 'zod';

const postalCode = z.object({
	postalCode: z
		.string()
		.trim()
		.max(20)
		.refine((zip) => validator.isPostalCode(zip, 'any'), {
			message: 'Postal code appears to be invalid.',
		})
		.optional(),
	country: z.string().max(255).nullish(),
});

export default postalCode;
